import { logout } from 'authentication'
import { startHolyLoader } from 'holy-loader'
import { useHandlerTokens } from '../utils/use-handler-tokens'

export const useLogout = () => {
  const { getAccessToken, closeSession } = useHandlerTokens()

  const handleLogout = async () => {
    startHolyLoader()

    const accessToken = await getAccessToken()

    await logout({ appHeader: 'panel', accessToken })
    closeSession()
  }

  return { handleLogout }
}
