import { useParams, usePathname, useSearchParams } from 'next/navigation'
import { PREVIOUS_QUERY_NAME, TAB_QUERY_NAME } from '@/shared/constants'

export const useUrlLocation = () => {
  const params = useParams()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const hasTabQuery = searchParams.has(TAB_QUERY_NAME)
  const tabQuery = searchParams.get(TAB_QUERY_NAME)
  const previousQuery = searchParams.get(PREVIOUS_QUERY_NAME)

  const previousUrl = hasTabQuery
    ? `${pathname}?${searchParams.toString()}`
    : pathname

  return {
    queriesParams: {
      tabQuery,
      previousQuery,
    },
    urlParams: {
      ...params,
    },
    pathname,
    previousUrl,
  }
}
