import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import type { EntityEnums } from '@/features/entity-enums'

export type EnumsState = {
  enums: EntityEnums
  setEnums: (enums: EntityEnums) => void
  resetEnums: () => void
}

const defaultEnums: EntityEnums = {
  EConfigHelp: [],
  ECallStatus: [],
  ECubaMaxServicesTypes: [],
  EOperatorsActions: [],
  EPaymentSource: [],
  EPaymentStatus: [],
  ETransactionTypes: [],
  EAppRoles: [],
  EDeviceType: [],
  EServiceStatus: [],
  EPaymentRules: [],
  ECurrencyTypes: [],
  EPaymentRulesActions: [],
  EPaymentRulesGroups: [],
  EAppTypes: [],
  EPaymentGatewaysTypes: [],
  ELockerShipmentPackageStatus: [],
  ELockerShipmentStatus: [],
  ETransportType: [],
}
// TODO: This must be moved to services/entity-enums with naming convention entity-enums.store.ts
export const useEntityEnumsStore = create<EnumsState>()(
  persist(
    (set) => ({
      enums: defaultEnums,
      setEnums: (enums) => set({ enums }),
      resetEnums: () => set({ enums: defaultEnums }),
    }),
    {
      name: 'enumsValue',
    }
  )
)
