import localForage from 'localforage'
import { useVersion } from '../utils/use-version'
import { useEntityEnumsStore } from './use-enums'

export const useClearCacheStorage = () => {
  const resetEnums = useEntityEnumsStore((state) => state.resetEnums)
  const resetVersion = useVersion((state) => state.resetVersion)

  const clearCacheStorage = () => {
    resetEnums()
    resetVersion()
    void localForage.removeItem('fcm_token')
  }

  return {
    clearCacheStorage,
  }
}
