import { create } from 'zustand'

export const optionsChanged = ['filterValue', 'sortOrder'] as const
export const options = [
  'filterValue',
  'sortOrder',
  'visibleIndex',
  'width',
  'fixed',
  'fixedPosition',
] as const

export type OptionChangedKey = (typeof optionsChanged)[number]

export type UseShowState = Record<
  OptionChangedKey | 'clearAll' | 'clearColumnsOrder',
  boolean
>

export type UseShowAction = {
  setShowNotificationBadge: (show: Partial<UseShowState>) => void
}

export const useShowNotificationBadge = create<UseShowState & UseShowAction>(
  (set) => ({
    filterValue: false,
    sortOrder: false,
    clearAll: false,
    clearColumnsOrder: false,
    setShowNotificationBadge: (params) =>
      set((state) => ({ ...state, ...params })),
  })
)
