import { AbortErrorStatusMessage, ApiError, StatusErrors } from 'libs'
import { ErrorConnectionContent, ErrorsMessages } from '../constants'

/**
 * Throws an API error with the specified status code, message, and errors.
 *
 * @param {{ errors: string[]; message: string; statusCode: number }} param0 - Object containing errors, message, and status code
 * @return {never} This function never returns a value
 */
export const throwApiError = ({
  errors,
  statusCode,
  message: statusMessage,
}: {
  errors: string[]
  message: string
  statusCode: number
}): never => {
  const errorMessage =
    statusMessage === AbortErrorStatusMessage
      ? ErrorConnectionContent.description
      : JSON.stringify(errors)

  throw new ApiError(statusMessage, errors, statusCode, errorMessage)
}

/**
 * Function that handles getting errors based on the type of error provided.
 *
 * @param {unknown} error - the error to process
 * @return {Array<string>} the array of errors or a default error message
 */
export const handlerGetErrors = (error: unknown) => {
  return error instanceof ApiError
    ? error.errors
    : [ErrorsMessages.SomethingWentWrong]
}

export const isErrorToLogout = (error: ApiError) =>
  [StatusErrors.Unauthorized, StatusErrors.Forbidden].some(
    (status) =>
      status.text === error.statusMessage || status.code === error.statusCode
  )
