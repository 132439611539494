import { useParams } from 'next/navigation'
import type { FilterDetails, FilterId } from '@/app/(panel)/_types/grid.types'

export const useFiltersDetails = ({
  atr,
  param,
}: {
  atr: FilterId
  param: string
}) => {
  const pathParameter = useParams()

  const filterDetails: FilterDetails = {
    atr,
    value: pathParameter[param] as string,
  }

  return { filterDetails }
}
