import { useEffect, useState } from 'react'

export const useMounted = () => {
  // @note hack to avoid hydration error when the component is created on server side render and hydrate on client side render. This component going to wrap the Store[*Component]
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return { isMounted }
}
