import { useAsync } from 'react-use'
import { getProfile } from '@/features/users/profile.service'
import { useHandlerTokens } from '../utils/use-handler-tokens'
import { useUsername } from '../utils/use-username'

export const useFetchUsername = () => {
  const { setUsername, username } = useUsername()

  const { getAccessToken } = useHandlerTokens()

  const { loading } = useAsync(async () => {
    if (username) {
      return
    }

    const accessToken = await getAccessToken()

    if (!accessToken) return

    const { data, success } = await getProfile({ accessToken })

    if (!success) return

    setUsername(data.user.fullName)
  }, [username])

  return {
    states: { loading },
  }
}
