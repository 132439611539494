import { create } from 'zustand'

type ShowLogoutLoaderState = {
  open: boolean
}

type ShowLogoutLoaderActions = {
  setShowLogoutLoader: (open: boolean) => void
}

export const useShowLogoutLoader = create<
  ShowLogoutLoaderActions & ShowLogoutLoaderState
>((set) => ({
  open: false,
  setShowLogoutLoader: (open) => set({ open }),
}))
