import type { EntityName } from '../types'

export const noDataText = 'No existen coincidencias'
export const SUCCESSFUL = 'Éxito'

export const menuDashboardName = 'Dashboard'
export const tabInfoName = 'Información'
export const InfoBasicName = 'Información básica'

export const entitiesName: Record<EntityName, string> = {
  users: 'Usuarios',
  devices: 'Dispositivos',
  paymentOperations: 'Operaciones',
  paymentTransactions: 'Transacciones',
  balanceTransfs: 'Transferencias de saldo',
  balanceHistory: 'Historial de saldo',
  ipInfo: 'Información IP',
  servCalls: 'LLamadas',
  servSms: 'Sms',
  cards: 'Tarjetas',
  addresses: 'Direcciones',
  identityDocs: 'Documentos',
  beneficiaries: 'Beneficiarios',
  phones: 'Teléfonos',
  emails: 'Correos electrónicos',
  servRemittances: 'Remesas',
  paymentRefunds: 'Reembolsos',
  servTopups: 'Recargas',
  operatorsActions: 'Logs de sistema',
  notifications: 'Notificaciones',
  promotions: 'Promociones',
  serviceConfig: 'Disponibilidad',
  paymentConfig: 'Reglas de pago',
  lockedCardsHistory: 'Historial de tarjetas bloqueadas',
  lockedDevicesHistory: 'Historial de dispositivos bloqueados',
  paymentGatewayConfig: 'Gateway',
  paymentConfigByUser: 'Reglas de pago por usuario',
  beneficiariesAccounts: 'Cuentas de beneficiarios',
  remittanceConfig: 'Configuración de remesa',
  servShopping: 'Tienda',
  systemConfig: 'Sistema',
  systemConfigByUser: 'Sistema por usuario',
  cardFailedHistory: 'Tarjetas fallidas',
  categories: 'Categorías',
  providers: 'Proveedores',
  servLockerShipmentPackages: 'Paquetes',
  servLockerShipments: 'Envíos',
  helpConfig: 'Mensajes de ayuda',
  servStore: 'Tienda',
  paymentMaxmind: 'Maxmind',
  servFlight: 'Vuelos',
}

export const groupItems = {
  root: 'Menú',
  accessInfo: 'Información de acceso',
  userInfo: 'Información de usuarios',
  services: 'Servicios',
  payments: 'Pagos',
  configurations: 'Configuraciones',
  system: 'Sistema',
  noneGroup: 'Sin grupo',
} as const

export const subMenuItems = {
  payment: 'Pago',
}

export const INTERNAL_USER = 'SYSTEM INTERNAL OPERATIONS'

export const ADMIN_ROLE = 'admin'
export const GROCER_ROLE = 'grocer'
export const OPERATOR_ROLE = 'operator'
export const INITIAL_ROLE = 'initial'
export const SYSTEM_ROLE = 'system'
export const STANDARD_ROLE = 'standard'

export const PREVIOUS_QUERY_NAME = 'previous'
export const TAB_QUERY_NAME = 'tab'

export const pagesLabels = {
  paymentConfig: 'Reglas',
  synchronize: 'Sincronización',
  cardFailedHistory: 'Tarjetas fallidas',
}
