import { useToken } from 'authentication'
import { startHolyLoader } from 'holy-loader'
import { MissingTokensError } from 'libs'
import { useRouter } from 'next/navigation'
import { ClientRoutes } from '@/shared/routes/client.route'
import pkg from '../../../../package.json'
import { useClearCacheStorage } from '../services/use-clear-cache-storage'

export const useHandlerTokens = (props?: {
  noLogout?: boolean
  onError?: (error?: unknown) => void
}) => {
  const { clearCacheStorage } = useClearCacheStorage()
  const router = useRouter()

  const handleLogoutByUnauthorized = (deleteTokens?: () => void) => {
    startHolyLoader()
    deleteTokens?.()
    clearCacheStorage()
    router.replace(ClientRoutes.Login)

    // @note Throw an error when tokens is missing to logout and not make a request
    throw new MissingTokensError()
  }

  const { getAccessToken, ...rest } = useToken({
    onError: (errorProps) => {
      return props?.noLogout
        ? props.onError?.()
        : handleLogoutByUnauthorized(errorProps?.deleteTokens)
    },
  })

  const _getAccessToken = () => getAccessToken('panel', pkg.version)

  const closeSession = () => {
    rest.deleteTokens()
    clearCacheStorage()
    router.replace(ClientRoutes.Login)
  }

  return { getAccessToken: _getAccessToken, closeSession, ...rest }
}
