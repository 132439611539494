import { create } from 'zustand'

type UseCollapsedState = {
  collapsed: boolean
  handleCollapsed: () => void
}

export const useCollapsed = create<UseCollapsedState>((set) => ({
  collapsed: false,
  handleCollapsed: () => set((state) => ({ collapsed: !state.collapsed })),
}))
