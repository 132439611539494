import type { AdditionalBaseResponse, BaseApiResponse } from 'libs'
import { request, responseAdapter } from 'libs'
import { ApiRoutes } from '@/shared/routes/api.routes'
import { getHeaders } from '@/shared/utils'

export const getProfile = async ({
  accessToken,
}: {
  accessToken: string
}): Promise<
  AdditionalBaseResponse<BaseApiResponse<{ user: { fullName: string } }>>
> => {
  const response = await request.get(ApiRoutes.GetProfile, {
    headers: getHeaders({ accessToken }),
  })

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}
