import { useState, useEffect } from 'react'

/**
 * Generates a custom hook that interacts with a global store and avoid issues with server side.
 *
 * @param {(callback: (state: T) => unknown) => unknown} store - A function that interacts with the global store.
 * @param {(state: T) => F} callback - A function that processes the state.
 * @return {F} The processed data from the global store.
 */
export const useGlobalStore = <T, F>(
  store: (callback: (state: T) => unknown) => unknown,
  callback: (state: T) => F
) => {
  const result = store(callback) as F
  const [data, setData] = useState<F>()

  useEffect(() => {
    setData(result)
  }, [result])

  return data
}
