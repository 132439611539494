export const enum ApiRoutes {
  Entity = '/entity',
  EntityEnums = '/entity/enums',
  EntityDetails = '/entity/details?entityName={entity}&id={id}',
  BanUser = '/user/lock/{toUserId}',
  VerifyUser = '/user/verify/{toUserId}',
  UnbanUser = '/user/unlock/{toUserId}',
  PaymentBalanceDebit = '/payment/balance-debit/{toUserId}',
  PaymentBalanceCredit = '/payment/balance-credit/{toUserId}',
  PaymentComplete = '/payment/complete/{toUserId}/{operationId}',
  PaymentCancel = '/payment/cancel/{toUserId}/{operationId}',
  PaymentCancelOrder = '/payment/cancel-order/{toUserId}',
  PaymentPartialCancelOrder = '/payment/cancel-order/partial/{toUserId}',
  PaymentRefund = '/payment/refund/{toUserId}/{operationId}',
  DiffusionNotification = '/notifications/all',
  PrivateNotification = '/notifications/{toUserId}',
  Promotions = '/promotions',
  DeletePromotion = '/promotions/{id}',
  UpdatePromotion = '/promotions/{promotionId}',
  UpsertServiceConfig = '/services',
  UpsertPaymentRuleGeneral = '/config/payment',
  UpsertPaymentRuleByUser = '/config/payment/{toUserId}',
  DeletePaymentConfigRule = '/config/payment/{ruleValue}/{toUserId}',
  MovePromotion = '/promotions/{id}/move',
  MoveServiceConfig = '/services/{serviceKey}/move',
  CreateOrGetGatewayConfig = '/config/payment-gateway/{type}',
  RecoverPaymentGatewayConfig = '/config/payment-gateway/recover/{id}',
  UpdateOrDeleteGatewayConfig = '/config/payment-gateway/{id}',
  SetDefaultGatewayConfig = '/config/payment-gateway/setDefault/{id}',
  EditRemittanceConfig = '/services/remittance/{id}',
  SynchronizeUser = '/user/sync/{toUserId}',
  AddRole = '/user/{toUserId}/role/{role}/add',
  RemoveRole = '/user/{toUserId}/role/{role}/remove',
  SyncTerritories = '/config/sync/territories/{countryId}',
  RefreshServiceForSale = '/config/refresh/service-for-sale',
  RefreshCategories = '/config/refresh/products-categories',
  RefreshTopupServices = '/config/refresh/topup-services',
  RefreshDeliveryPoints = '/config/refresh/delivery-points',
  RefreshFlightAirports = '/config/refresh/flight-airports',
  RefreshFlightAirlines = '/config/refresh/flight-airlines',
  UpsertSystemConfigGeneral = '/config/system',
  UpsertSystemConfigByUser = '/config/system/{toUserId}',
  GetProfile = '/profile',
  CreateOrGetCategories = '/store/categories',
  RemoveOrEditCategory = '/store/categories/{id}',
  GetProviders = '/store/providers',
  GetProvidersCategories = '/store/providers/{id}/categories',
  SetRelationsCategory = '/store/categories/{id}/associated',
  UpdateStoreProvider = '/store/provider/{id}',
  SetPackageAsReceived = '/locker/shipment/{toUserId}/{id}/package/{pid}',
  SetProviderEnabledStatus = '/store/providers/{id}/enabled-status',
  UpdateHelpersMessage = '/config/help/{helpKey}',
  GetProviderCategoriesRelated = '/store/providers/{id}/categories-associated',
  UpdateProviderCategoriesRelated = '/store/provider/{id}/categories-associated',
  UpdateProviderCategoriesExcluded = '/store/providers/{id}/categories-excluded',
  UpdateProviderConfig = '/store/provider-configuration/{id}',
  GetRefreshOptions = '/config/refresh/options',
}
