import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type UsernameState = {
  username: string
  setUsername: (username: string) => void
}

export const useUsername = create<UsernameState>()(
  persist(
    (set) => ({
      username: '',
      setUsername: (username: string) => set({ username }),
    }),
    {
      name: 'username',
    }
  )
)
