import type { DataGrid, IRowDraggingProps } from 'devextreme-react/data-grid'
import { throwApiError } from '@/shared/handlers/error.handler'
import type { UpdatePresentationOrder } from '@/shared/types'
import { useHandlerTokens } from '../utils/use-handler-tokens'
import { useHandlerResponse } from '../utils/use-handler-response'

type ReorderEvent = Parameters<Required<IRowDraggingProps>['onReorder']>[number]

type UseReorderPresentationParam = {
  updatePresentationOrder: UpdatePresentationOrder
  bodyKeys: {
    id: string
    belowReference: string
  }
  id: string
}
export const useReorderPresentation = <TCustomStore>({
  updatePresentationOrder,
  bodyKeys,
  id,
}: UseReorderPresentationParam) => {
  const { handlerError, handlerSuccess } = useHandlerResponse()
  const { getAccessToken } = useHandlerTokens()

  const handleReorderPresentation = async (
    e: ReorderEvent,
    dataGridRef: DataGrid<TCustomStore> | null
  ) => {
    try {
      if (e.toIndex === e.fromIndex) return

      const accessToken = await getAccessToken()
      const referenceId = e.component.getKeyByRowIndex(e.toIndex)

      const body = Object.entries(bodyKeys).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [value]: key === 'id' ? referenceId : e.toIndex < e.fromIndex,
        }),
        {}
      )

      const { success, message, errors, statusCode } =
        await updatePresentationOrder({
          id: e.itemData[id],
          accessToken,
          body,
        })

      if (!success) throwApiError({ message, errors, statusCode })

      if (dataGridRef) {
        await dataGridRef.instance.refresh()
        handlerSuccess({ message })
      }
    } catch (error) {
      handlerError({ error })
    }
  }

  return {
    handleReorderPresentation,
  }
}
