import { useCallback, useState } from 'react'

export const useFormDialog = ({
  clearFormErrors,
  resetForm,
  conditionalsOnOpenDialog,
  functionsOnOpenDialog,
  functionsOnCloseDialog,
}: {
  conditionalsOnOpenDialog?: boolean[]
  functionsOnOpenDialog?: (<T>(...params: T[]) => void)[]
  functionsOnCloseDialog?: (<T>(...params: T[]) => void)[]
  resetForm: () => void
  clearFormErrors: () => void
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  const handleOpenDialog = (open: boolean) => {
    if (conditionalsOnOpenDialog?.some(Boolean)) return

    if (open && functionsOnOpenDialog) {
      functionsOnOpenDialog.forEach((func) => {
        func()
      })
    }

    clearForm()
    setOpenDialog(open)
  }

  const clearForm = useCallback(() => {
    resetForm()
    clearFormErrors()
  }, [clearFormErrors, resetForm])

  const closeDialog = useCallback(() => {
    if (functionsOnCloseDialog) {
      functionsOnCloseDialog.forEach((func) => {
        func()
      })
    }
    clearForm()
    setOpenDialog(false)
  }, [clearForm, functionsOnCloseDialog])

  return {
    openDialog,
    handleOpenDialog,
    closeDialog,
  }
}
