import { getNotificationCount, useNotificationCountStore } from 'notifications'
import { useAsync } from 'react-use'
import { useHandlerTokens } from '../utils/use-handler-tokens'

export const useFetchNotificationCount = () => {
  const { getAccessToken } = useHandlerTokens()
  const { setNotificationCountResponse, notificationCountResponse } =
    useNotificationCountStore()

  const { loading } = useAsync(async () => {
    if (notificationCountResponse) return

    const accessToken = await getAccessToken()

    if (!accessToken) return

    const notificationCount = await getNotificationCount({
      accessToken,
      appHeader: 'panel',
    })

    setNotificationCountResponse(notificationCount)
  }, [])

  return {
    states: {
      loading,
    },
  }
}
