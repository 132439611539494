import * as Sentry from '@sentry/nextjs'
import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'
import pkg from '../../../package.json'

export const version = pkg.version

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const isEmpty = <T>(value: T) =>
  value === undefined ||
  value === null ||
  value === '' ||
  (typeof value === 'object' && !Object.keys(value).length)

export const getEnumExpireTime = () =>
  new Date(new Date().setMonth(new Date().getMonth() + 1)).getTime()

export const getHeaders = ({ accessToken }: { accessToken?: string }) => {
  return {
    Authorization: `Bearer ${accessToken}`,
    app: 'panel',
    'Accept-Language': 'es-ES,es',
  }
}

export const formatCurrencyUSD = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)
}

export const removeBrackets = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value.join(',')
  }

  return value.replace(/[\\[\]{}()]/g, '')
}

export const formatBoolean = (value: boolean) => {
  return value ? 'Si' : 'No'
}

export const formatDateToLocalString = (date: string) => {
  const isoDate = parseDateToISO(date)
  return format(isoDate, 'PPP', { locale: es })
}

export const getVersion = () => version

export const transformToMegabytes = (kilobytes: number) => {
  return kilobytes / 1024
}

export const transformToKilobytes = (bytes: number) => {
  return bytes / 1024
}

export const transformToBytes = (megabytes: number) => {
  return megabytes * 1000000
}

export const formatDateTimeDDMMYYYY = (date: Date | string | number) => {
  return format(new Date(date), 'dd/MM/yyyy, hh:mm:ss aa')
}
export const formatDateDDMMYYYY = (date: Date | string | number) => {
  const isoDate = parseDateToISO(date)
  const formattedDate = format(isoDate, 'dd/MM/yyyy')
  return formattedDate
}

export const formatDateTime = (date: Date | string | number) => {
  return format(new Date(date), 'dd MMM hh:mm:ss aa', { locale: es })
}

export const formatToYYYYMMDD = (date: Date | string | number) => {
  return format(new Date(date), 'yyyy-MM-dd')
}

export const parseStringDate = (dateString: string): Date => {
  const ISODate = new Date(dateString).toISOString()
  return parseISO(ISODate)
}

export const compareDates = (
  date1: Date | string | number,
  date2: Date | string | number
) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  d1.setHours(0, 0, 0, 0)
  d2.setHours(0, 0, 0, 0)

  return d1.getTime() < d2.getTime()
}
export const formatCamelCaseToSentence = (key: string) => {
  return key
    .replace(/(?<temp1>[A-Z])/g, ' $1')
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const disabledDate = (date: Date) => compareDates(date, new Date())

/**
 * Returns a new Date object with only the date portion from the given input.
 *
 * @param {string | number | Date} date - The input date to extract the date portion from.
 * @return {Date} A new Date object with only the date portion.
 *
 * This code snippet defines a function called getCorrectDate that takes in a parameter date of type string, number, or Date. The function creates a Date object based on the input date and then creates a new Date object with only the date portion, excluding the time and timezone information. The function returns this new Date object.
 */
export const getCorrectDate = (date: string | number | Date): Date => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)

  return dtDateOnly
}

/**
 * Parses a given date into ISO format.
 *
 * @param {string | number | Date} date - The date to be parsed.
 * @return {Date} The parsed date in ISO format.
 */
export const parseDateToISO = (date: string | number | Date): Date => {
  const strDate = new Date(date).toISOString().split('T')[0]
  const isoDate = parseISO(strDate)
  return isoDate
}

export const copyToClipboard = ({ content }: { content: string }) => {
  void navigator.clipboard.writeText(content)
}

export const captureException = (
  error: unknown,
  context?: Record<string, unknown>
) => {
  const isLocal = process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'
  if (!isLocal) {
    Sentry.captureException(error, {
      extra: context,
    })
  } else {
    console.error(error)
  }
}

export function convertTimeToSeconds(time: string): number {
  const timeParts = time.split(':')
  const hours = parseInt(timeParts[0], 10)
  const minutes = parseInt(timeParts[1], 10)
  const seconds = parseInt(timeParts[2], 10)
  return hours * 3600 + minutes * 60 + seconds
}

export const isNill = <T>(value?: T | null): value is T =>
  value === null || value === undefined
