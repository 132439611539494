import { type ApiError } from 'libs'
import { useEffect } from 'react'
import { captureException } from '@/shared/utils'

export const useCatchError = (
  error: (ApiError | Error) & { digest?: string }
) => {
  useEffect(() => {
    // Log the error to Sentry
    captureException(error, {
      digest: error.digest,
    })
  }, [error])
}
