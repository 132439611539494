import { DEFAULT_ERROR_MESSAGE } from 'libs'
import { transformToKilobytes } from '../utils'
import type { ErrorContent } from '../types'

export const MAX_FILE_SIZE_BT = 1000000
export const MAX_FILE_SIZE_KB =
  transformToKilobytes(MAX_FILE_SIZE_BT).toFixed(0)

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
]

export const ErrorsMessages = {
  Invalid: 'Campo inválido.',
  Required: 'Campo obligatorio.',
  SomethingWentWrong: 'Algo salió mal.',
  RegisterEmail: 'Por favor registre su email.',
  MustBeGreaterThanZero: 'El saldo debe ser mayor a cero.',
  MustBeLessThanOrEqualToBalance:
    'El saldo debe ser mayor a cero y menor o igual que el saldo actual.',
  MustBeLessThanOrEqualToAmount:
    'El monto debe ser mayor a cero y menor o igual que el monto total.',
  InvalidImageType: 'Se aceptan archivos .jpg, .jpeg, .png y .webp.',
  RequiredImage: 'Imagen requerida',
  InvalidMaxSizeImage: `El tamaño máximo del archivo es de ${MAX_FILE_SIZE_KB} KB.`,
  InvalidDate: `Fecha inválida`,
  StartDateGreaterEndDate:
    'La fecha inicial no puede ser mayor que la fecha final',
  EndDateLessStartDate:
    'La fecha final no puede ser menor que la fecha inicial',
  InvalidBoolean: 'Valor inválido.',
  InvalidServiceType: 'Tipo de servicio invalido.',
  MustBeInteger: 'Debe ser un número entero.',
  ImageRequired: 'La imagen es requerida.',
  MustBePositive: 'Debe ser un número positivo.',
  SelectAtLeastOneImage: 'Seleccione una imagen para la web o para la app.',
  SelectAtLeastOneCategory: 'Seleccione al menos una categoría.',
  PositiverOrderNumber: 'El orden debe ser un número mayor a cero',
  Max255: 'El campo no puede tener más de 255 caracteres',
  Max100: 'El campo no puede tener más de 100 caracteres',
  Required3DS: `Este campo es obligario si 3DS esta habilitado`,
  OnlyOriginForBalance: 'Solo puedes reembolsar el saldo al origen',
}

export const ErrorConnectionContent: ErrorContent = {
  title: 'Error de conexión',
  description: 'La peticion esta tomando mas de lo esperado. Intente de nuevo',
}

export const MissingTokensErrorContent: ErrorContent = {
  title: 'Sesión expirada',
  description: 'Debes iniciar sesión para realizar esta acción.',
}

export const InternalServerErrorContent: ErrorContent = {
  title: 'Error',
  description: DEFAULT_ERROR_MESSAGE,
}
