import { decodeAccessToken } from 'libs'
import { ADMIN_ROLE } from '@/shared/constants'
import { useHandlerTokens } from './use-handler-tokens'

export const useTokenPayload = () => {
  const { accessToken } = useHandlerTokens()

  if (!accessToken) return { accessTokenPayload: undefined }

  const accessTokenPayload = decodeAccessToken(accessToken)

  const isAdminUser = accessTokenPayload.roles.includes(ADMIN_ROLE)

  const getIsCurrentLoggedUser = (userId: string) => {
    return accessTokenPayload.userId.toString() === userId
  }

  return {
    accessTokenPayload: {
      ...accessTokenPayload,
      isAdminUser,
      getIsCurrentLoggedUser,
    },
  }
}
