import { getCookie } from 'cookies-next'
import {
  ACCESS_TOKEN,
  AbortErrorStatusMessage,
  ApiError,
  InternalServerErrorStatusMessage,
  MissingTokensError,
  MissingTokensStatusMessage,
  isErrorToLogout,
} from 'libs'
import { usePathname } from 'next/navigation'
import { ErrorDescription, toast } from 'ui'
import {
  ErrorConnectionContent,
  InternalServerErrorContent,
  MissingTokensErrorContent,
  SUCCESSFUL,
} from '@/shared/constants'
import { handlerGetErrors } from '@/shared/handlers/error.handler'
import { ClientRoutes } from '@/shared/routes/client.route'
import { captureException } from '@/shared/utils'
import { useLogout } from '../services/use-logout'

const duration = 6000
type UseHandlerResponseReturnType = {
  handlerSuccess: ({ message }: { message: string }) => void
  handlerError: ({ error }: { error: unknown }) => void
}

export const useHandlerResponse = (): UseHandlerResponseReturnType => {
  const { handleLogout } = useLogout()
  const pathname = usePathname()

  const handlerSuccess = ({ message }: { message: string }) => {
    toast({
      variant: 'default',
      title: SUCCESSFUL,
      description: message,
      duration: 5000,
    })
  }

  const handlerError = async ({ error }: { error: unknown }) => {
    if (
      error instanceof MissingTokensError &&
      error.statusMessage === MissingTokensStatusMessage
    ) {
      return toast({
        variant: 'destructive',
        title: MissingTokensErrorContent.title,
        description: MissingTokensErrorContent.description,
        duration,
      })
    }

    if (
      error instanceof ApiError &&
      isErrorToLogout(error) &&
      pathname !== ClientRoutes.Login
    ) {
      return handleLogout()
    }

    if (
      error instanceof ApiError &&
      error.statusMessage === AbortErrorStatusMessage
    ) {
      return toast({
        variant: 'destructive',
        title: ErrorConnectionContent.title,
        description: ErrorConnectionContent.description,
        duration,
      })
    }

    if (
      error instanceof ApiError &&
      error.statusMessage === InternalServerErrorStatusMessage
    ) {
      return toast({
        variant: 'destructive',
        title: InternalServerErrorContent.title,
        description: InternalServerErrorContent.description,
        duration,
      })
    }

    const errors = handlerGetErrors(error)

    if (!(error instanceof ApiError)) {
      captureException(error, {
        extra: {
          accessToken: getCookie(ACCESS_TOKEN) || 'NO_ACCESS_TOKEN',
        },
      })
    }

    return toast({
      variant: 'destructive',
      title: 'Error',
      description: <ErrorDescription errors={errors} />,
      duration,
    })
  }

  return {
    handlerSuccess,
    handlerError,
  }
}
