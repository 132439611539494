import { useRef } from 'react'

type HandlerRequest<TRequest> = TRequest extends (
  params: infer P & { signal: AbortSignal }
) => infer R
  ? {
      request: (params: P & { signal?: AbortSignal }) => R
      params: P
      response: R
    }
  : never

export type RequestFunction<TParams, TResponse> = (
  params: TParams & { signal?: AbortSignal }
) => Promise<TResponse>

export type RF<TParams, TResponse> = RequestFunction<TParams, TResponse>

// @note: This can be used in a future
export const useRequest = <
  TRequest extends (args: TParams) => TResponse,
  TParams = HandlerRequest<TRequest>['params'],
  TResponse = HandlerRequest<TRequest>['response'],
>(
  request: HandlerRequest<TRequest>['request']
) => {
  const abortController = useRef<AbortController>()

  const makeRequest = (params: TParams) => {
    abortController.current = new AbortController()
    return request({ ...params, signal: abortController.current.signal })
  }

  const cancelRequest = () => {
    abortController.current?.abort()
  }

  return [makeRequest, cancelRequest] as const
}
