import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export type VersionProps = {
  version: string
}

export type VersionAction = {
  setVersion: (version: string) => void
  resetVersion: () => void
}

export const useVersion = create<VersionProps & VersionAction>()(
  persist(
    (set) => ({
      version: '',
      setVersion: (version) => set({ version }),
      resetVersion: () => set({ version: '' }),
    }),
    { name: 'version' }
  )
)
